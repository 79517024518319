import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { approveAction, getAllowanceDetails, JoinNowAction } from "../Action/ContractActions.js";
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { useParams } from "react-router-dom";
import config from '../config/config'
import { connection } from "../helper/connection.js";
import { updatemissed } from "../Action/apiAction.js";
import ZolttanABI from '../ABI/ZolttanABI.json'
import { type WalletClient, useWalletClient } from 'wagmi'
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function JoinNow(props) {
  var { id } = useParams();

  const [referalAddress, setReferalAddress] = useState("")
  const [Error, setError] = useState()
  const [load, setload] = useState(false)
  const [joinedamt, setjoinedamt] = useState(0)
  let chainid = config.NetworkId ;
  const { data: walletClient } = useWalletClient({ chainid })
  const Connection = useSelector((state) => state.walletConnection)
  const [walletconnection,setwalletconnection] = useState({})

  useEffect(() => {
    setRefAddress()
    getdetails()
  }, [walletClient,Connection])

  const handlechange = (e) => {
    const { value } = e.target;
    setError()
    setReferalAddress(value)
  }

  const setRefAddress = () => {
    if (!isEmpty(id)) {
      localStorage.setItem("referaladdress",id)
      setReferalAddress(id)
    }else{
      let add = localStorage.getItem("referaladdress");
      if(add){
       setReferalAddress(add);
      }
    }
  }

  async function getdetails() {
    let datas = await connection();
    setwalletconnection(datas)
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "") {
      if (datas.userdetails &&
        datas.userdetails.isExist) {
        window.location.href = "/dashboard"
      } else {
      }
    }
  }

  const Joinnow = async () => {
    setload(true)
    setError()
      const { status, message } = await JoinNowAction(referalAddress)
      if ((status == true) && !isEmpty(message)) {
        setload(false)
        setReferalAddress("")
        toastAlert('success', message)
        setTimeout(() => {
          window.location.href = "/dashboard"
        }, 1000)
      }
      else {
        // toastAlert('error',message)
        setload(false)
        return setError(message)
      }
  }
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Join Now</h2>
                  <div className="primary_box dash_box mt-4">
                    <div className="form-group row">
                      <div className="col-lg-6 m-auto text-start">
                        <label for="exampleFormControlInput1" class="form-label">Referral ID</label>
                        <input type="email" class="form-control primary_inp" name="exampleFormControlInput1" placeholder="Enter referral ID" value={referalAddress} onChange={handlechange} disabled={id && id != undefined && id != null && id != "" ? "disabled" : ""} />
                        {!isEmpty(Error) ? <span className="errorText">{Error}</span> : ""}

                        {
                          walletconnection && walletconnection.address && walletconnection.address == '' ?
                            <button
                              className="primary_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#connect_wallet_modal"
                            >
                              Connect Wallet
                            </button>
                            :  walletconnection.address && walletconnection.address != '' ?
                                <div>
                                  {load == false ?
                                    <button className="primary_btn" onClick={() => Joinnow()}>Submit</button>
                                    :
                                    <button className="primary_btn" disabled={true}>Processing........</button>
                                  }
                                </div>
                                : <button
                                  className="primary_btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#connect_wallet_modal"
                                >
                                  Connect Wallet
                                </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
