import Web3 from "web3";
import config from "../config/config";
import Web3Modal from "web3modal";
import { providerOptions } from "../config/providerOptions";
import ZolttanAbi from '../ABI/ZolttanABI.json'
import store from '../store';


const web3Modal = new Web3Modal({
  providerOptions, // required,
  cacheProvider: true, // optional
});

var isLoad = false

export async function connection() {
  var currentProvider = store.getState()
    var reduxVal = store.getState();
    var provider = (reduxVal && reduxVal.walletConnection&& reduxVal.walletConnection && reduxVal.walletConnection.web3 && reduxVal.walletConnection.web3.key)?reduxVal.walletConnection.web3:{}
    console.log(provider,"======fromconnection")
    if(provider && provider!=null && provider!=undefined && provider!="" && provider.key){
      console.log(provider,"=====inside the prov")
    var web3 = new Web3(provider);
    if (typeof web3 !== "undefined") {
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      var currAddr = result[0];
      var bnbBalance = await web3.eth.getBalance(currAddr);
      bnbBalance = bnbBalance / 10 ** 18
      let deficontract = new web3.eth.Contract(ZolttanAbi,config.ZolttanAddress)
      let userdetails = await deficontract.methods.users(currAddr).call();
      if (currAddr === undefined) currAddr = "";
      if (network === config.NetworkId) {
        return({
          network: network,
          web3: provider,
          address: result[0],
          provider: provider,
          connect: "yes",
          userdetails: userdetails
        });
      }else{
        return({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "no",
          userdetails: ""
        });
      }
    }else{
      return({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "no",
          userdetails: ""
        });
    }
  }else{
    return({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "no",
          userdetails: ""
        });
  }

  
}