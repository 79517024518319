import isEmpty from "is-empty"
import { connection } from "../helper/connection"
import config from '../config/config'
import { Multicall } from "ethereum-multicall"
import { bignumber } from "../helper/customMath"
import Web3 from "web3";
import BusdABI from '../ABI/BusdABI.json'
import ZolttanABI from '../ABI/ZolttanABI.json'
import { convert } from "../helper/convert"
import syncEach from 'sync-each';
import { levelupgrade, updateregister } from "./apiAction"

export async function getAllowanceDetails(contractAddr, spender) {//busdadd,Zolttanaddress
    var get = await connection();
    console.log(get,"======incontractaction")
    try {
        if (get && get.web3) {
            var web3 = new Web3(get.web3);
            var owner = get.address;
            var Contract = new web3.eth.Contract(BusdABI, contractAddr);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            result1 = parseFloat(result1) / 10 ** 18

            var deficontract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
            var joinamt = await deficontract.methods.JoinAmount().call();
            joinamt = parseFloat(joinamt) / (10 ** 18);

            return {
                value: result1,
                status: true,
                joinamt: joinamt
            };
        } else {
            return {
                value: {},
                status: false,
                joinamt: 0
            };
        }
    } catch (err) {
        console.log('errrrrrrrrrererererr: ', err);
        return {
            value: {},
            status: false,
            joinamt: 0
        };
    }
}
export async function approveAction(contractAddr,RefAddress) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = new Web3(get.web3);
            var address = get.address;
            var deficontract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
            var joinamt = await deficontract.methods.JoinAmount().call();
            if (isEmpty(RefAddress)) {
                return {
                    status: false,
                    message: 'Referral address should not be empty'
                }
            }
            else if ((RefAddress).toLowerCase() == (address).toLowerCase()) {
                return {
                    status: false,
                    message: "Wallet and Referral address are same"
                }
            }
            var checkaddress = web3.utils.isAddress(RefAddress)
            if (checkaddress == false) {
                return {
                    status: false,
                    message: 'Invalid Address'
                }
            }
            else {
                joinamt = parseFloat(joinamt) / (10 ** 18);
                var Contract = new web3.eth.Contract(BusdABI, contractAddr);

                var balance = await Contract.methods.balanceOf(address).call();
                balance = parseFloat(balance) / (10 ** 18);
                if (parseFloat(joinamt) > parseFloat(balance)) {
                    return {
                        value: {},
                        status: false,
                        message: 'Insufficient BUSD Balance',
                        approvereturnval: 0,
                        joinamt: 0
                    };
                }
                var approveAmt = 1000000000 * (10 ** 18);
                approveAmt = await convert(approveAmt);
                var result1 = await Contract.methods.approve(
                    config.ZolttanAddress,
                    approveAmt.toString()
                ).send({ from: address });

                var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
                    result1.events.Approval.returnValues.value : 0
                approvereturnval = parseFloat(approvereturnval) / 10 ** 18
                return {
                    value: result1,
                    status: true,
                    message: 'Approved success',
                    approvereturnval: approvereturnval,
                    joinamt: joinamt
                };
            }
        } else {
            return {
                value: {},
                status: false,
                message: 'Transaction Rejected',
                approvereturnval: 0,
                joinamt: 0
            };
        }
    } catch (err) {
        console.log('err: ', err);
        return {
            value: {},
            status: false,
            message: 'Transaction Rejected',
            approvereturnval: 0,
            joinamt: 0
        };
    }
}
export const JoinNowAction = async (RefAddress) => {
    var get = await connection();
    try {
        if (get && get.web3) {
            var address = get.address
            var web3 = new Web3(get.web3);
            if (isEmpty(RefAddress)) {
                return {
                    status: false,
                    message: 'Referral address should not be empty'
                }
            }
            else if ((RefAddress).toLowerCase() == (address).toLowerCase()) {
                return {
                    status: false,
                    message: "Wallet and Referral address are same"
                }
            }
            var checkaddress = web3.utils.isAddress(RefAddress)
            if (checkaddress == false) {
                return {
                    status: false,
                    message: 'Invalid Address'
                }
            }
            else {
                var Contracts = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress)
                let balance = await web3.eth.getBalance(address);
                var userExist = await Contracts.methods.users(address).call();
                var checkisExist = userExist.isExist
                if (checkisExist == true) {
                    return {
                        status: false,
                        message: 'You Already joined'
                    }
                }
                else {
                    var refAddrsCheck = await Contracts.methods.users(RefAddress).call();
                    if (refAddrsCheck.isExist == false) {
                        return {
                            status: false,
                            message: 'Referral address does not exist'
                        }
                    }
                    else {
                        var joinAmt = await Contracts.methods.initialAmount().call();
                        joinAmt = parseFloat(joinAmt);
                        if(parseFloat(balance)>parseFloat(joinAmt)){
                            var joinReg = await Contracts.methods.regUser(RefAddress).send({ from: address , value : joinAmt.toString() })
                            if (joinReg && joinReg.status == true && joinReg.transactionHash != '') {
                                let loguser = await Contracts.methods.users(address).call();
                                let reg_data = {
                                    address: address,
                                    referralparent: RefAddress,
                                    userid: loguser.id
                                }
                                await updateregister(reg_data);
                                return {
                                    status: true,
                                    message: 'Successfully joined'
                                }
                            }
                            else {
                                return {
                                    status: false,
                                    message: 'Transaction rejected'
                                }
                            }
                        }else{
                            return {
                                status: false,
                                message: 'Insufficient Balance'
                            }
                        }
                    }
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Transaction rejected'
            }
        }
    }
    catch (err) {
        console.log(err, 'eeeeeeee')
        return {
            status: false,
            message: 'Transaction rejected'
        }
    }
}
export const GetLevelDetailsAction = async (settreeprogress, setallSelected, setmynetwork, setdownlinefirst, setdownlinesecond, setdownlinethird, 
    setdownlinefourth, setlevel1, setlevel2, setlevel3, setSelected)=>{
    try { 
        var get = await connection()
        if (get && get.web3 && get.address) {
            var address = get.address;
            var web3 = new Web3(get.web3);
            const multicall = new Multicall({
                web3Instance: web3,
            })
            var contractCallContext = [
                {
                    reference: "details",
                    contractAddress: config.ZolttanAddress,
                    abi: ZolttanABI,
                    calls: [
                        {
                            reference: "directReferral",
                            methodName: "viewplanBUserReferral",
                            methodParameters: [address]
                        },
                        {
                            reference: "viewUserReferral",
                            methodName: "viewUserReferral",
                            methodParameters: [address]
                        },
                        {
                            reference: "userprofile",
                            methodName: "users",
                            methodParameters: [address],
                        },
                    ],
                },
            ];
            const results = await multicall.call(contractCallContext)
            var dirReferral = await getFormatMulticall(results, "directReferral", 0)
            var downlinenode = await getFormatMulticall(results, "viewUserReferral", 0)
            var users = await getFormatMulticall(results, "userprofile", 0);
            var myreferral = dirReferral;
            var isexist = users[0];
            var userid = await bignumber(users[1]);
            var currlevel = await bignumber(users[3])
            let _my_ref = [];
            // console.log(myreferral, 'myreferralmyreferral')
            if (myreferral.length > 0) {

                for (let noderef in myreferral) {
                    var _myref = [
                        {
                            reference: "details",
                            contractAddress: config.ZolttanAddress,
                            abi: ZolttanABI,
                            calls: [
                                {
                                    reference: "userprofile",
                                    methodName: "users",
                                    methodParameters: [myreferral[noderef]],
                                },
                                {
                                    reference: "viewUserReferral",
                                    methodName: "viewUserReferral",
                                    methodParameters: [myreferral[noderef]],
                                },
                            ],
                        },
                    ];
                    const _results = await multicall.call(_myref);
                    var _userprofile = await getFormatMulticall(_results, "userprofile", 0);
                    var _downlinenode = await getFormatMulticall(_results, "viewUserReferral", 0);
                    let _userid = await bignumber(_userprofile[1]);
                    let _currlevel = await bignumber(_userprofile[3]);
                    let _downline = {
                        "address": myreferral[noderef],
                        "level": _currlevel,
                        "userid": _userid,
                        "downlinenode": _downlinenode
                    }
                    _my_ref.push(_downline);
                    if (parseFloat(noderef) + 1 == myreferral.length) {
                        setmynetwork(_my_ref);
                    }
                }
            }

            //******downlinecalculation  1************//
            let downline1 = [];
            let downline2arr = [];
            let downline3arr = [];
            let downline4arr = [];
            let downline5arr = [];
            let downline2 = [];
            let downline3 = [];
            let downline4 = [];
            if (downlinenode.length > 0) {
                for (let node in downlinenode) {
                    var downlineCallContext = [
                        {
                            reference: "details",
                            contractAddress: config.ZolttanAddress,
                            abi: ZolttanABI,
                            calls: [
                                {
                                    reference: "userprofile",
                                    methodName: "users",
                                    methodParameters: [downlinenode[node]],
                                },
                                {
                                    reference: "viewUserReferral",
                                    methodName: "viewUserReferral",
                                    methodParameters: [downlinenode[node]],
                                },
                            ],
                        },
                    ];
                    const results1 = await multicall.call(downlineCallContext);
                    var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);

                    var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);

                    let userid1 = await bignumber(userprofile1[1]);
                    let currlevel1 = await bignumber(userprofile1[3]);
                    let downline_1 = {
                        "address": downlinenode[node],
                        "level": currlevel1,
                        "userid": userid1,
                        "downlinenode": downlinenode1
                    }
                    downline1.push(downline_1);
                    downline2arr = downline2arr.concat(downlinenode1);
                    if (parseFloat(node) + 1 == downlinenode.length) {
                        setdownlinefirst(downline1);
                    }
                }
            }
            //*******downline2*******//
            if (downline2arr.length > 0) {
                for (let node1 in downline2arr) {
                    var downlineCallContext0 = [
                        {
                            reference: "details",
                            contractAddress: config.ZolttanAddress,
                            abi: ZolttanABI,
                            calls: [
                                {
                                    reference: "userprofile",
                                    methodName: "users",
                                    methodParameters: [downline2arr[node1]],
                                },
                                {
                                    reference: "viewUserReferral",
                                    methodName: "viewUserReferral",
                                    methodParameters: [downline2arr[node1]],
                                },
                            ],
                        },
                    ];
                    const results2 = await multicall.call(downlineCallContext0);
                    var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
                    var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
                    let userid2 = await bignumber(userprofile2[1]);
                    let currlevel2 = await bignumber(userprofile2[3]);
                    let downline_2 = {
                        "address": downline2arr[node1],
                        "level": currlevel2,
                        "userid": userid2,
                        "downlinenode": downlinenode2
                    }
                    downline2.push(downline_2);
                    downline3arr = downline3arr.concat(downlinenode2);
                    if (parseFloat(node1) + 1 == downline2arr.length) {
                        setdownlinesecond(downline2);
                    }
                }
            }

            //************downline3******************************//
            if (downline3arr.length > 0) {
                for (let node2 in downline3arr) {
                    var downlineCallContext1 = [
                        {
                            reference: "details",
                            contractAddress: config.ZolttanAddress,
                            abi: ZolttanABI,
                            calls: [
                                {
                                    reference: "userprofile",
                                    methodName: "users",
                                    methodParameters: [downline3arr[node2]],
                                },
                                {
                                    reference: "viewUserReferral",
                                    methodName: "viewUserReferral",
                                    methodParameters: [downline3arr[node2]],
                                },
                            ],
                        },
                    ];
                    const results3 = await multicall.call(downlineCallContext1);
                    var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
                    var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
                    let userid3 = await bignumber(userprofile3[1]);
                    let currlevel3 = await bignumber(userprofile3[3]);
                    let downline_3 = {
                        "address": downline3arr[node2],
                        "level": currlevel3,
                        "userid": userid3,
                        "downlinenode": downlinenode3
                    }
                    downline3.push(downline_3);
                    downline4arr = downline4arr.concat(downlinenode3);
                    if (parseFloat(node2) + 1 == downline3arr.length) {
                        setdownlinethird(downline3);
                    }
                }
            }

            //************downline4******************************//
            if (downline4arr.length > 0) {
                for (let node3 in downline4arr) {
                    var downlineCallContext3 = [
                        {
                            reference: "details",
                            contractAddress: config.ZolttanAddress,
                            abi: ZolttanABI,
                            calls: [
                                {
                                    reference: "userprofile",
                                    methodName: "users",
                                    methodParameters: [downline4arr[node3]],
                                },
                                {
                                    reference: "viewUserReferral",
                                    methodName: "viewUserReferral",
                                    methodParameters: [downline4arr[node3]],
                                },
                            ],
                        },
                    ];
                    const results4 = await multicall.call(downlineCallContext3);
                    var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
                    var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
                    let userid4 = await bignumber(userprofile4[1]);
                    let currlevel4 = await bignumber(userprofile4[3]);
                    let downline_4 = {
                        "address": downline4arr[node3],
                        "level": currlevel4,
                        "userid": userid4,
                        "downlinenode": downlinenode4
                    }
                    downline4.push(downline_4);
                    downline5arr = downline5arr.concat(downlinenode4);
                    if (parseFloat(node3) + 1 == downline4arr.length) {
                        setdownlinefourth(downline4);
                    }
                }
            }
            //****************************2nd level tree************************//
            // let line1arr=[];
            // if(downline1.length>0){
            //   for(let y in downline1){
            //     if(downline1[y].downlinenode.length>0){
            //       let subline=[];
            //       for(let z in downline1[y].downlinenode){
            //         var IndexVal = downline2.findIndex(val => val.address == downline1[y].downlinenode[z]);
            //         let user1 = {
            //           name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
            //         }
            //         subline.push(user1);
            //         if(parseInt(z)+1==downline1[y].downlinenode.length){
            //           let mainline = {
            //             "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
            //             "children": subline
            //           }
            //           line1arr.push(mainline);
            //         }
            //       }
            //     }else{
            //       let mainline = {
            //           "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
            //           "children": []
            //         }
            //         line1arr.push(mainline);
            //     }
            //     if(parseInt(y)+1==downline1.length){
            //       let mainlinearr = {
            //         name : "ID "+userid +" ("+currlevel+")",
            //         "children":line1arr
            //       }
            //       setSelected(mainlinearr);
            //       setallSelected(mainlinearr);
            //       let my_network = downline1.concat(downline2);
            //       //setmynetwork(my_network);
            //     }
            //   }
            // }else{
            //   let mainlinearr = {
            //     name : "ID "+userid +" ("+currlevel+")",
            //     "children":[]
            //   }
            //   setSelected(mainlinearr);
            //   setallSelected(mainlinearr);
            //   let my_network = downline1.concat(downline2);
            //   setmynetwork(my_network);
            // }

            // //************************3rd level tree ************************************//
            // let line1arr=[];
            // if(downline1.length>0){
            //   syncEach(downline1, async function (items,next) {
            //     if(items && items.downlinenode && items.downlinenode.length>0){
            //       let subline=[];
            //       syncEach(items.downlinenode, async function (subitems,next1) {
            //         var IndexVal = downline2.findIndex(val => val.address == subitems);
            //         if(IndexVal>=0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length>0){
            //           let subline2 = [];
            //           syncEach(downline2[IndexVal].downlinenode, async function (thir_items,next2) {
            //             var IndexVal = downline3.findIndex(val => val.address == thir_items);
            //                 let user12 = {
            //                     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
            //                 }
            //                 subline2.push(user12);
            //                 next2();
            //           },function( error , success){
            //             let mainline = {
            //                     "name": "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")",
            //                     "children": subline2
            //                   }
            //                   subline.push(mainline);
            //             next1();
            //           })
            //         }else{
            //           var IndexVal = downline2.findIndex(val => val.address == subitems);
            //               let user1 = {
            //                 name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
            //               }
            //               subline.push(user1);
            //           next1();
            //         }
            //       },function (errs,t_Items) {
            //         let mainline = {
            //               "name": "ID "+items.userid+" ("+items.level+")",
            //               "children": subline
            //             }
            //             console.log("push33")
            //             line1arr.push(mainline);
            //         next();
            //       })
            //     }else{
            //         let mainline = {
            //               "name": "ID "+items.userid+" ("+items.level+")",
            //               "children": []
            //             }
            //             line1arr.push(mainline);
            //             next();
            //       }
            //     },function (err,transformedItems) {
            //           let mainlinearr = {
            //             name : "ID "+userid +" ("+currlevel+")",
            //             "children":line1arr
            //           }
            //           setSelected(mainlinearr);
            //           setallSelected(mainlinearr);
            //           settreeprogress(false)
            //           let my_network = downline1.concat(downline2);
            //   })
            //   }else{
            //         let mainlinearr = {
            //           name : "ID "+userid +" ("+currlevel+")",
            //           "children":[]
            //         }
            //         setSelected(mainlinearr);
            //         setallSelected(mainlinearr);
            //         settreeprogress(false)
            //         let my_network = downline1.concat(downline2);
            //         setmynetwork(my_network);
            //   }

            //**************************4th level tree*********************//
            let line1arr = [];
            if (downline1.length > 0) {
                syncEach(downline1, async function (items, next) {
                    if (items && items.downlinenode && items.downlinenode.length > 0) {
                        let subline = [];
                        syncEach(items.downlinenode, async function (subitems, next1) {
                            let IndexVal = downline2.findIndex(val => val.address == subitems);
                            if (IndexVal >= 0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length > 0) {
                                let subline2 = [];
                                syncEach(downline2[IndexVal].downlinenode, async function (thir_items, next2) {
                                    let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
                                    if (IndexVal1 >= 0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length > 0) {
                                        let subline3 = [];
                                        syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items, next3) {
                                            let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                                            let user22 = {
                                                name: "ID " + downline4[Index_Val].userid + " (" + downline4[Index_Val].level + ")"
                                            }
                                            subline3.push(user22);
                                            next3();
                                        }, function (error, success) {
                                            let mainline = {
                                                "name": "ID " + downline3[IndexVal1].userid + " (" + downline3[IndexVal1].level + ")",
                                                "children": subline3
                                            }
                                            setlevel3(mainline);
                                            subline2.push(mainline);
                                            next2();
                                        })
                                    } else {
                                        let IndexVal = downline3.findIndex(val => val.address == thir_items);
                                        let user1 = {
                                            name: "ID " + downline3[IndexVal].userid + " (" + downline3[IndexVal].level + ")"
                                        }
                                        subline2.push(user1);
                                        next2();
                                    }
                                    // let user12 = {
                                    //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                                    // }
                                    // subline2.push(user12);
                                    // next2();
                                }, function (error, success) {
                                    let mainline = {
                                        "name": "ID " + downline2[IndexVal].userid + " (" + downline2[IndexVal].level + ")",
                                        "children": subline2
                                    }
                                    setlevel2(mainline)
                                    subline.push(mainline);
                                    next1();
                                })
                            } else {
                                var IndexValue = downline2.findIndex(val => val.address == subitems);
                                let user1 = {
                                    name: "ID " + downline2[IndexValue].userid + " (" + downline2[IndexValue].level + ")"
                                }
                                subline.push(user1);
                                next1();
                            }
                        }, function (errs, t_Items) {
                            let mainline = {
                                "name": "ID " + items.userid + " (" + items.level + ")",
                                "children": subline
                            }
                            setlevel1(mainline)
                            line1arr.push(mainline);
                            next();
                        })
                    } else {
                        let mainline = {
                            "name": "ID " + items.userid + " (" + items.level + ")",
                            "children": []
                        }
                        setlevel1(mainline);
                        setlevel2([])
                        line1arr.push(mainline);
                        next();
                    }
                }, function (err, transformedItems) {
                    let mainlinearr = {
                        name: "ID " + userid + " (" + currlevel + ")",
                        "children": line1arr
                    }
                    setSelected(mainlinearr);
                    setallSelected(mainlinearr);
                    settreeprogress(false)
                    let my_network = downline1.concat(downline2);
                })
            } else {
                let mainlinearr = {
                    name: "ID " + userid + " (" + currlevel + ")",
                    "children": []
                }
                setSelected(mainlinearr);
                setallSelected(mainlinearr);
                settreeprogress(false);
                let my_network = downline1.concat(downline2);
                setmynetwork(my_network);
            }

            return {
                status: true,
                isexist: isexist,
                UserProfileId: userid
            }
        }
        else {
            return {
                status: false,
                isexist: '',
                message: 'Error on server'
            }
        }
    }
    catch (err) {
        console.log('errasassssswwwwwwasasas: ', err);
        return {
            status: false,
            isexist: '',
            message: 'Error on server'

        }
    }

   
}


export const GetDetailsAction = async () => {
    try {
        var get = await connection()
        if (get && get.web3 && get.address) {
            var address = get.address;
            var web3 = new Web3(get.web3);
            const multicall = new Multicall({
                web3Instance: web3,
            })

            var contractCallContext = [
                {
                    reference: "details",
                    contractAddress: config.ZolttanAddress,
                    abi: ZolttanABI,
                    calls: [
                        {
                            reference: "userprofile",
                            methodName: "users",
                            methodParameters: [address],
                        },
                        {
                            reference: "totaluser",
                            methodName: "totalUsers",
                            methodParameters: []

                        },
                        {
                            reference: "viewUserReferral",
                            methodName: "viewUserReferral",
                            methodParameters: [address]
                        },
                        {
                            reference: "directReferral",
                            methodName: "viewplanBUserReferral",
                            methodParameters: [address]
                        },
                        {
                            reference: "joinedLength",
                            methodName: "joinedLength",
                            methodParameters: []
                        },
                        {
                            reference: "UserlegCount",
                            methodName: "getUserlegCount",
                            methodParameters: [address]
                        },
                        {
                            reference: "royaltycount",
                            methodName: "getRoyalparticipantsLength",
                            methodParameters: []
                        },
                        {
                            reference: "globalroyalityAmount",
                            methodName: "globalroyalityAmount",
                            methodParameters: []
                        },
                        {
                            reference: "royalityAmount",
                            methodName: "royalityAmount",
                            methodParameters: []
                        },
                        {
                            reference: "getUsersJoinedLast24Hours",
                            methodName: "getUsersJoinedLast24Hours",
                            methodParameters: []
                        },
                        {
                            reference: "levelprice1",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [1] 
                        },
                        {
                            reference: "levelprice2",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [2] 
                        },
                        {
                            reference: "levelprice3",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [3] 
                        },
                        {
                            reference: "levelprice4",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [4] 
                        },
                        {
                            reference: "levelprice5",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [5] 
                        },
                        {
                            reference: "levelprice6",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [6] 
                        },
                        {
                            reference: "levelprice7",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [7] 
                        },
                        {
                            reference: "levelprice8",
                            methodName: "LEVEL_PRICE",
                            methodParameters: [8] 
                        },
                        {
                            reference: "planB",
                            methodName: "planB",
                            methodParameters: [address]
                        },
                        
                    ],
                },
            ];

            const results = await multicall.call(contractCallContext)
            var users = await getFormatMulticall(results, "userprofile", 0);
            var totalusers = await getFormatMulticall(results, "totaluser", 0)
            var dirReferral = await getFormatMulticall(results, "directReferral", 0)
            var downlinenode = await getFormatMulticall(results, "viewUserReferral", 0)
            var joinedLength = await getFormatMulticall(results, "joinedLength", 0)
            var UserlegCount = await getFormatMulticall(results, "UserlegCount", 0)
            var royaltycount = await getFormatMulticall(results, "royaltycount", 0)
            var UserlegCount = await getFormatMulticall(results, "UserlegCount", 0)
            var planB = await getFormatMulticall(results, "planB", 0)
            var lp1 = await getFormatMulticall(results, "levelprice1", 0)
            var lp2 = await getFormatMulticall(results, "levelprice2", 0)
            var lp3 = await getFormatMulticall(results, "levelprice3", 0)
            var lp4 = await getFormatMulticall(results, "levelprice4", 0)
            var lp5 = await getFormatMulticall(results, "levelprice5", 0)
            var lp6 = await getFormatMulticall(results, "levelprice6", 0)
            var lp7 = await getFormatMulticall(results, "levelprice7", 0)
            var lp8 = await getFormatMulticall(results, "levelprice8", 0)
            var totroy = await bignumber(planB[3])
            var totglobroy = await bignumber(planB[4])
            var _lp1 = await bignumber(lp1[0]);
            var _lp2 = await bignumber(lp2[0]);
            var _lp3 = await bignumber(lp3[0]);
            var _lp4 = await bignumber(lp4[0]);
            var _lp5 = await bignumber(lp5[0]);
            var _lp6 = await bignumber(lp6[0]);
            var _lp7 = await bignumber(lp7[0]);
            var _lp8 = await bignumber(lp8[0]);
            var getUsersJoinedLast24Hours = await getFormatMulticall(results, "getUsersJoinedLast24Hours", 0)
            var globalroyalityAmount = await getFormatMulticall(results, "globalroyalityAmount", 0)
            var royalityAmount = await getFormatMulticall(results, "royalityAmount", 0)
            var _globalroyalityAmount = await bignumber(globalroyalityAmount[0]);
            var _royalityAmount = await bignumber(royalityAmount[0]);
            var _joinedLength = await bignumber(joinedLength[0]);
            var _UserlegCount = await bignumber(UserlegCount[0]);
            var isexist = users[0];
            var userid = await bignumber(users[1]);
            var currlevel = await bignumber(users[3])
            var eligibleroyaltycount = await bignumber(royaltycount[0])
            var last24hruser = await bignumber(getUsersJoinedLast24Hours[0]) 
            var yourearnedAmt = await bignumber(users[4]);
            yourearnedAmt = parseFloat(yourearnedAmt) / 10 ** 18;
            var locktokenAmt = await bignumber(users[5]);
            locktokenAmt = parseFloat(locktokenAmt) / 10 ** 18;
            var joinedDate = await bignumber(users[12]);
            var myreferral = dirReferral
            var upgradeAmount = await bignumber(users[6]);
            var totalEarnedamt = await bignumber(users[7]);
            var referralincome = await bignumber(users[8]);
            var levelincome = await bignumber(users[9]);
            var mgincome = await bignumber(users[10]);
            var mpincome = await bignumber(users[11]);
            upgradeAmount = parseFloat(upgradeAmount) / 10 ** 18;
            totalEarnedamt =  parseFloat(totalEarnedamt) / 10 ** 18;
            referralincome =  parseFloat(referralincome) / 10 ** 18;
            levelincome =  parseFloat(levelincome) / 10 ** 18;
            mgincome = parseFloat(mgincome) / 10 ** 18;
            mpincome = parseFloat(mpincome) / 10 ** 18;
            var totaluser = await bignumber(totalusers[0]);
            return {
                status: true,
                isexist: isexist,
                UserProfileId: userid,
                AllUsers: totaluser,
                dirReferralcount: myreferral && myreferral.length,
                upgradeAmount: upgradeAmount,
                yourearnedAmt: yourearnedAmt,
                locktokenAmt: locktokenAmt,
                currentLevel: currlevel,
                "joinedLength":_joinedLength,
                "totalEarnedamt":totalEarnedamt,
                "levelincome":levelincome,
                'referralincome':referralincome,
                "mgincome" : mgincome,
                "mpincome" : mpincome,
                legcount   : _UserlegCount,
                eligibleroyaltycount: eligibleroyaltycount,
                totalroyaltyamt:_royalityAmount,
                totalglobalroyaltyamt : _globalroyalityAmount,
                last_24hruser : last24hruser,
                joined_date : joinedDate,
                "levelprice1" : _lp1,
                "levelprice2" : _lp2,
                "levelprice3" : _lp3,
                "levelprice4" : _lp4,
                "levelprice5" : _lp5,
                "levelprice6" : _lp6,
                "levelprice7" : _lp7,
                "levelprice8" : _lp8,
                "totroy"      : totroy,
                "totglobroy"  : totglobroy
            }
        }
        else {
            return {
                message: 'Error on server',
                status: false,
                isexist: false,
                UserProfileId: 0,
                AllUsers: 0,
                dirReferralcount: 0,
                upgradeAmount: 0,
                yourearnedAmt: 0,
                locktokenAmt: 0,
                currentLevel: 0,
                "joinedLength":0,
                "totalEarnedamt":0,
                "levelincome": 0 ,
                'referralincome': 0,
                "mgincome" : 0,
                "mpincome" : 0,
                legcount   : 0,
                eligibleroyaltycount : 0,
                totalroyaltyamt:0,
                totalglobalroyaltyamt : 0,
                last_24hruser: 0 ,
                joined_date : 0,
                "levelprice1" : 0,
                "levelprice2" : 0,
                "levelprice3" : 0,
                "levelprice4" : 0,
                "levelprice5" : 0,
                "levelprice6" : 0,
                "levelprice7" : 0,
                "levelprice8" : 0,
                "totroy"      : 0,
                "totglobroy"  : 0
            }
        }
    }
    catch (err) {
        console.log('errasasasasas: ', err);
        return {
            message: 'Error on server',
            status: false,
            isexist: false,
            UserProfileId: 0,
            AllUsers: 0,
            dirReferralcount: 0,
            upgradeAmount: 0,
            yourearnedAmt: 0,
            locktokenAmt: 0,
            currentLevel: 0,
            "joinedLength":0,
            "totalEarnedamt":0,
            "levelincome": 0 ,
            'referralincome': 0,
            "mgincome" : 0,
            "mpincome" : 0,
            legcount   : 0,
            eligibleroyaltycount : 0,
            totalroyaltyamt:0,
            totalglobalroyaltyamt : 0,
            last_24hruser : 0,
            joined_date : 0,
            "levelprice1" : 0,
            "levelprice2" : 0,
            "levelprice3" : 0,
            "levelprice4" : 0,
            "levelprice5" : 0,
            "levelprice6" : 0,
            "levelprice7" : 0,
            "levelprice8" : 0,
            "totroy"      : 0,
            "totglobroy"  : 0
        }
    }
}



export function getFormatMulticall(results, name, pos) {
    try {
        var index = results && results.results && results.results.details &&
            results.results.details.callsReturnContext &&
            results.results.details.callsReturnContext.findIndex(val => val.reference == name);
        var returnVal = results.results.details.callsReturnContext[index] &&
            results.results.details.callsReturnContext[index].returnValues ?
            results.results.details.callsReturnContext[index].returnValues : "";
        return returnVal;
    } catch (err) {
        console.log(err, "==+++++++")
        return "";
    }
}

// export async function getFormatMulticall(results, name, pos) {

//     try {
//         var returnVal = (results && results.results && results.results[name]
//             && results.results[name].callsReturnContext &&
//             results.results[name].callsReturnContext &&
//             results.results[name].callsReturnContext[pos] &&
//             results.results[name].callsReturnContext[pos].returnValues &&
//             results.results[name].callsReturnContext[pos].returnValues[0]) ?
//             results.results[name].callsReturnContext[pos].returnValues[0] : "";
//         return returnVal;
//     } catch (err) {
//         return "";
//     }
// }


export const UpgradeAction = async (upgradeAmount, isexist, currentLevel) => {
    try {
        var get = await connection()
        if (get && get.web3 && get.address) {
            var address = get.address;
            var web3 = new Web3(get.web3);
            if (parseFloat(upgradeAmount) <= 0) {
                return {
                    status: false,
                    message: 'Upgrade amount is too low'
                }
            }
            if (isexist == false) {
                return {
                    status: false,
                    message: 'Join first to proceed further'
                }
            }
            else {
                var Contract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
                var data = await Contract.methods.upgradeNextLevel().send({ from: address });
                if (data && data.status == true && (!isEmpty(data.transactionHash))) {

                    var userss = await Contract.methods.users(address).call();
                    let _level = userss && userss.currentLevel;
                    _level = parseFloat(_level)
                    let upgrade_data = {
                        address: address,
                        level: _level
                    }
                    await levelupgrade(upgrade_data);
                    return {
                        status: true,
                        message: "Upgraded successfully"
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction rejected'
                    }
                }
            }
        }
    }
    catch (err) {
        console.log(err, 'erreeeeerrrrr')
        return {
            status: false,
            message: 'Transaction rejected'
        }
    }
}

export const DepositAction = async (depositamt, isexist) => {
    try {
        var get = await connection();
        var address = get.address;
        var web3 = new Web3(get.web3);
        
        if(depositamt == null || depositamt == undefined || depositamt =="" ){
            return {
                status: false,
                message: 'Enter Valid Amount'
            }
        }else if (parseFloat(depositamt) <= 0) {
            return {
                status: false,
                message: 'Deposit amount is too low'
            }
        }else if (parseFloat(depositamt) > 160) {
            return {
                status: false,
                message: 'Maximum Deposit Amount : 160 BUSD'
            }
        }else if (isexist == false) {
            return {
                status: false,
                message: 'Join first to proceed further'
            }
        }
        else {
            var Contract = new web3.eth.Contract(BusdABI, config.BUSDAddress);
            var approveAmt = depositamt * (10 ** 18);
            approveAmt = await convert(approveAmt);
            var result1 = await Contract.methods.approve(
                config.ZolttanAddress,
                approveAmt.toString()
            ).send({ from: address });
            if(result1 && result1.transactionHash && result1.status == true){
                var defiContract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
                var data = await defiContract.methods.depositToUpgrade(approveAmt.toString()).send({ from: address });
                if (data && data.status == true && (!isEmpty(data.transactionHash))) {
                    return {
                        status: true,
                        message: "Deposited successfully"
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction rejected'
                    }
                }
            }
        }
    }
    catch (err) {
        console.log(err, 'eeeeeeeeeeeerrrr')
        return {
            status: false,
            message: 'Transaction rejected'
        }
    }
}

export const WithdrawAction = async (withdrawAmt, upgradePending, isexist , curr_lev) => {
    try {
        var get = await connection();
        var address = get.address;
        var web3 = new Web3(get.web3);
        if (parseFloat(withdrawAmt) <= 0) {
            return {
                status: false,
                message: 'Withdraw amount is too low'
            }
        }
        if (isexist == false) {
            return {
                status: false,
                message: 'Join first to proceed further'
            }
        }
        else {
            let upgradependingAmt = parseFloat(upgradePending);
            if (upgradependingAmt > 0 && parseInt(curr_lev)<10 ) {
                return {
                    status: false,
                    message: 'you can withdraw,only after upgrade'
                }
            }
            else {
                var Contract = new web3.eth.Contract(ZolttanABI, config.ZolttanAddress);
                var data = await Contract.methods.claimRewards().send({ from: address });
                if (data && data.status == true && (!isEmpty(data.transactionHash))) {
                    return {
                        status: true,
                        message: "Claimed successfully"
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction rejected'
                    }
                }
            }
        }
    }
    catch (err) {
        console.log(err, 'eeeeeeeeeeeerrrr')
        return {
            status: false,
            message: 'Transaction rejected'
        }
    }
}